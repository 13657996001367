import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Model({ closeModal }) {
    let navigate = useNavigate();

    // State to track selected city and course
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');

    const handleSubmit = () => {
        closeModal();
        navigate('/term');
    };

    return (
        <>
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-y-auto'>
                <div className="bg-white my-32 p-5 rounded-lg overflow-y-auto h-4/5">
                    <div className="flex items-center justify-between border-b pb-4">
                        <h3 className="text-lg font-semibold text-gray-900">Apply Now</h3>
                        <button type="button" className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg w-8 h-8 flex justify-center items-center" onClick={closeModal}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </button>
                    </div>
                    <form className="mt-4">
                        <div className="max-w-md mx-auto">
                            <div className='flex mb-4'>
                                <div className="mr-4">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 text-start">Name</label>
                                    <input type="text" name="name" id="name" className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="Enter your name" />
                                </div>
                                <div className="ml-4">
                                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 text-start">Phone Number</label>
                                    <input type="tel" name="phone" id="phone" className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="Enter your phone number" />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-start">Email</label>
                                <input type="email" name="email" id="email" className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="Enter your email" />
                            </div>
                            <fieldset className="mb-4">
                                <legend className="text-lg font-semibold mb-2 text-start">Select Course *</legend>
                                <label className="flex items-center mb-2">
                                    <input type="radio" name="course" value="live-classes" className="form-radio h-5 w-5 text-gray-600" onChange={() => { setSelectedCourse('live-classes'); setSelectedCity(''); }} /><span className="ml-2">Rs30,000 Live Classes + Rec + Student Sup</span>
                                </label>
                                <label className="flex items-center mb-2">
                                    <input type="radio" name="course" value="physical-classes" className="form-radio h-5 w-5 text-gray-600" onChange={() => setSelectedCourse('physical-classes')} /><span className="ml-2">Rs50,000 Physical Classes + Rec + Student Sup</span>
                                </label>
                            </fieldset>

                            {/* Show schedule based on selected course */}
                            {selectedCourse === 'live-classes' && (
                                <fieldset className="mb-4">
                                    <legend className="text-lg font-semibold mb-2 text-start">August Batch (Pakistan Time) *</legend>
                                    <label className="flex items-center mb-2">
                                        <input type="radio" name="schedule" value="4pm-6pm" className="form-radio h-5 w-5 text-gray-600" /><span className="ml-2">4:00pm-6:00pm (Sat-Sun) 17th August</span>
                                    </label>
                
                                </fieldset>
                            )}

                            {/* Show city selection if Rs50,000 Physical Classes + Rec + Student Sup is selected */}
                            {selectedCourse === 'physical-classes' && (
                                <fieldset className="mb-4">
                                    <legend className="text-lg font-semibold mb-2 text-start">Select City *</legend>
                                    <label className="flex items-center mb-2">
                                        <input type="radio" name="city" value="lahore" className="form-radio h-5 w-5 text-gray-600" onChange={() => setSelectedCity('lahore')} checked={selectedCity === 'lahore'} /><span className="ml-2">Lahore</span>
                                    </label>
                                </fieldset>
                            )}

                            {/* Show schedule based on selected city */}
                            {selectedCourse === 'physical-classes' && selectedCity && (
                                <fieldset className="mb-4">
                                    <legend className="text-lg font-semibold mb-2 text-start">August Batch (Pakistan Time) *</legend>
                                    <label className="flex items-center mb-2">
                                        <input type="radio" name="schedule" value="4pm-6pm" className="form-radio h-5 w-5 text-gray-600" /><span className="ml-2">4:00pm-6:00pm (Sat-Sun) 17th August</span>
                                    </label>
                                    {/* Add more schedule options for Lahore if needed */}
                                </fieldset>
                            )}

                            {/* Payment Information */}
                            <fieldset className="mb-4">
                                <legend className="text-lg font-semibold mb-2 text-start">Transfer to Book Your Slot *</legend>
                                <label className="flex items-center mb-2">
                                    <input type="radio" name="payment" value="bank-account" className="form-radio h-5 w-5 text-gray-600" /><span className="ml-2">PK46MEZN0002570109401248 Meezan Bank Wealth University 02570109401248 (Try this if Previous doesn't work)</span>
                                </label>
                            </fieldset>
                            <div className="mb-6">
                                <label htmlFor="payment-screenshot" className="block text-gray-700 text-sm font-bold mb-2 text-start">
                                    Upload Payment Screenshot *
                                </label>
                                <input
                                    type="file"
                                    id="payment-screenshot"
                                    name="payment-screenshot"
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>

                            {/* Submission note */}
                            <p className="text-red-500 text-xs italic mb-6">
                                Your Payment will be verified by our team so whatsapp it as well at +92-323-0423791
                            </p>

                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Model;
