// App.js
import React from "react";
// import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Model from "./components/Model";
import Screen1 from "./components/Screen1";
import Terms from "./components/Terms";

function App() {
  return (
    <div className="app">
      <Router>
        <div>
          {/* Other components like navigation can go here */}
          <Routes>
            <Route path="/" element={<Screen1 />} exact />
            {/* <Route path="/about} /> */}
            <Route path="/term" element={<Terms />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
