import React from "react";

const MyWebpage = () => {
  // Define image URLs here
  const customImages = [
    "/images/s1.jpg",
    "/images/s2.jpg",
    "/images/s3.jpg",
    "/images/s4.jpg",
    "/images/s5.jpg",
    "/images/s6.jpg",
  ];

  return (
    <>
      <div className="min-h-screen md:mx-auto md:max-w-screen-lg">
        {" "}
        {/* Center content on desktop */}
        <h1 className="text-5xl text-center font-bold mt-10 mb-10">
          READY FOR A GLIMPSE INTO YOUR FUTURE?
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {" "}
          {/* Added gap-4 for spacing between grid items */}
          {customImages.map((image, index) => (
            <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
              <img
                src={image}
                alt={`Custom Image ${index + 1}`}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ))}
        </div>
      </div>
      {/*<div
        id="team"
        className="mx-1 lg:px-32 lg:my-[150px] flex justify-center items-center w-screen h-screen "
      >
         <img src="/images/team.jpg" alt="error" className="lg:w-[50%] w-full" /> 
      </div>*/}
    </>
  );
};

export default MyWebpage;
