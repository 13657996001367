import React from "react";
import YouTube from "react-youtube";

const Video = () => {
  const videoId = "AU7BWHYxXSM";

  // Custom options for the YouTube player
  const opts = {
    height: "100%", // These values are overridden by className, but are necessary
    width: "100%", // to prevent console warnings or errors.
  };

  return (
    <div
      className="flex justify-center items-center"
      style={{ width: "100vw", height: "60vh" }}
    >
      <div className="w-full h-full">
        <YouTube videoId={videoId} opts={opts} className="w-full h-full" />
      </div>
    </div>
  );
};

export default Video;
