// components/HeroSection.js
import React, { useState } from "react";
import Navbar from "./Navbar";
import Model from "./Model";

function HeroSection() {
  const [show, setShow] = useState(false);

  const openModal = () => {
    console.log("first");
    setShow(true);
  };

  const closeModal = () => {
    console.log("second");
    setShow(false);
  };

  return (
    <>
      {show && <Model closeModal={closeModal} />}
      <div className="py-10 md:p-12 text-center bg-gray-800">
        <p className="text-white px-4 mt-6 text-lg md:text-xl lg:text-2xl font-bold">
          Tyaar ho Gareebi se nikalnay k leye?
        </p>

        <a
          href="https://wa.me/message/LUMIHXU6NRINC1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            data-modal-target="crud-modal"
            data-modal-toggle="crud-modal"
            className="bg-gray-700 text-white text-sm font-bold px-3 py-2 my-8 md:my-16 md:px-6 md:py-3 md:text-lg rounded-md transition duration-300 ease-in-out hover:bg-gray-600"
          >
            LET'S GET STARTED
          </button>
        </a>
      </div>
    </>
  );
}

export default HeroSection;
