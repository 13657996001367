import React from "react";
import HeroSection from "./HeroSection";

import Profilesection from "./Profilesection";
import Lowerside from "./Lowerside";
import Testimonies from "./Testimonies";
import Screenshots from "./Screenshots";
import End from "./End";
import Footer from "./Footer";

function Screen1() {
  return (
    <div>
      <HeroSection />
      <Profilesection />
      <Lowerside />
      <Testimonies />
      
      <Screenshots />
      <End />
      <Footer />
    </div>
  );
}

export default Screen1;
