// components/HeroSection.js
import React, { useState } from "react";
import "./HeroSection.css";
import Navbar from "./Navbar";
import Model from "./Model";
import Video from "./Video";

function HeroSection() {
  const [show, setshow] = useState(false);

  const openModal = () => {
    console.log("first");
    setshow(true);
  };

  const closeModal = () => {
    console.log("second");
    setshow(false);
  };

  return (
    <>
      {show && <Model closeModal={closeModal} />}
      {}
      <div className="hero-section py-5 ">
        <Navbar />
        <div className="hero-content mx-5 md:mx-16">
          <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold my-10">
            Build Your 7-Figure Ecommerce Business
          </h1>
          <a
            href="https://wa.me/message/LUMIHXU6NRINC1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              data-modal-target="crud-modal"
              data-modal-toggle="crud-modal"
              className="cta-button text-sm font-bold px-3 py-3 my-16 md:px-10 md:py-8 md:text-xl"
            >
              LET'S GET STARTED
            </button>
          </a>
        </div>
      </div>
      <div>
      <Video />
      </div>
    </>
  );
}

export default HeroSection;
