import { useState } from "react";

import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [navItems, setNavItems] = useState([
    { name: "Home", href: "/", current: true },
    { name: "About", href: "/#about", current: false },
    { name: "Testimonials", href: "/#testimonials", current: false },
    { name: "Team", href: "/#team", current: false },
  ]);

  const handleItemClick = (index) => {
    const updatedNavItems = [...navItems];
    updatedNavItems.forEach((item, i) => {
      item.current = i === index;
    });
    setNavItems(updatedNavItems);
  };

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="mx-5 md:mx-16 ">
            <div className="relative flex h-16 items-center justify-around">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />

                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex flex-shrink-0 ">
                <img
                  className="h-20 w-auto"
                  src={process.env.PUBLIC_URL + "/images/logo.png"}
                  alt="Your Company"
                />
                {/* <p className="text-2xl font-bold">
                                Nxt Gen
                               </p> */}
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-end">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navItems.map((item, index) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          "text-white font-bold hover:border-b-4 px-3 py-2 ",
                        )}
                        aria-current={item.current ? "page" : undefined}
                        onClick={() => handleItemClick(index)}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navItems.map((item, index) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-black hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium",
                  )}
                  aria-current={item.current ? "page" : undefined}
                  onClick={() => handleItemClick(index)}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
