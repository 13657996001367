import React, { useState } from "react";
import "../App.css";
import Model from "./Model";
function Lowerside() {
  const [show, setshow] = useState(false);

  const openModal = () => {
    console.log("first");
    setshow(true);
  };

  const closeModal = () => {
    console.log("second");
    setshow(false);
  };
  return (
    <div class="mt-22 py-10 md:p-12 text-center bg-image">
      <h1 class="text-white px-4 font-bold text-xl md:text-4xl lg:text-6xl leading-tight">
        MY MISSION IS TO GIVE YOU THE EXACT STRATEGIES, SYSTEMS, AND PROCESSES
        TO OPTIMIZE YOUR ECOMMERCE BUSINESS FOR MAXIMUM GROWTH
      </h1>
      
    </div>
  );
}

export default Lowerside;
