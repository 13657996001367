import Footer from "./Footer";
import "./HeroSection.css";
import Navbar from "./Navbar";

function Terms() {
  return (
    <>
      <div className="hero-section py-5 h-screen">
        <Navbar />
        <div className="hero-content mx-5 h-4/5 flex items-center justify-center md:mx-16">
          <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold my-10">
            Build Your 7-Figure Ecommerce Business
          </h1>
        </div>
      </div>
      <div className="mx-1 lg:mx-32 lg:my-32 mt-10 flex justify-center items-center">
        <img src="/images/approved.jpg" alt="error" />
      </div>
      <Footer />
    </>
  );
}

export default Terms;
