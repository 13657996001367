import React, { useState } from "react";
import "../App.css";
import Model from "./Model";

const Profilesection = () => {
  const [show, setshow] = useState(false);

  const openModal = () => {
    setshow(true);
  };

  const closeModal = () => {
    setshow(false);
  };
  return (
    <>
      <div
        id="about"
        className="flex flex-col lg:flex-row md:items-center bg-white px-5 py-20 md:px-5"
      >
        <div className="lg:w-1/2">
          <div className="flex items-center justify-center sm:justify-start">
            <img
              src={process.env.PUBLIC_URL + "/images/i1.jpg"}
              alt="Lara Teaching"
              className="image1 "
            />
            <img
              src={process.env.PUBLIC_URL + "/images/i2.png"}
              alt="Lara Teaching"
              className="image2 hidden lg:block "
            />
          </div>
        </div>
        <div className=" flex lg:w-1/2 mt-5 flex-col justify-center items-center lg:items-start">
          <h1 className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 namebox">
            HI, I’M ANAS ALI
          </h1>
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            WITH MY HELP, YOU’LL BE ABLE TO
          </h2>
          <ul>
            <li className="flex items-center mb-2">
              <CheckIcon />
              Generate more sales and make more money.
            </li>
            <li className="flex items-center mb-2">
              <CheckIcon />
              Get consistent, predictable, and profitable growth month over
              month.
            </li>
            <li className="flex items-center mb-2">
              <CheckIcon />
              Get 1:1 input on how to optimize your unique store for maximum
              growth and profit.
            </li>
            <li className="flex items-center mb-2 ">
              <CheckIcon />
              Create a sustainable, enjoyable eCommerce business that supports
              your financial & lifestyle goals.
            </li>
          </ul>
          <p className="text-gray-700 text-xl text-blue-700 ">
            I offer the complete eCommerce business framework that teaches
            online entrepreneurs how to optimize & grow their online business.
          </p>

          <a
            href="https://wa.me/message/LUMIHXU6NRINC1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="cta-button text-sm font-bold px-3 py-3 my-8 md:px-10 md:py-8 md:text-xl">
              LET'S GET STARTED
            </button>
          </a>

          <p className="text-gray-700 px-4 mt-2 text-md md:text-lg lg:text-xl">
            Parklane Tower,706 7th Floor,Businessbay
          </p>
          <p className="text-gray-700 px-4 mt-2 text-md md:text-lg lg:text-xl">
            Dha Phase 5, 3rd Floor, 37CCA, Lahore
          </p>

          {show && <Model closeModal={closeModal} />}
        </div>
      </div>
    </>
  );
};

const CheckIcon = () => (
  <span className="inline-block text-green-500 mr-2">✓</span>
);

export default Profilesection;
